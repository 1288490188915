import { message, notification } from "antd";
import { PAGE_SIZE } from "queries/queries.constant";
import {
    useMutationCreatePlan,
    useMutationDeleteSubscription,
    useMutationEditPlan,
    useQueryMultiPkgsList,
    useQuerySubscriptionData,
    useQuerySubscriptionPlan,
    useQueryUpdateStatus
} from "queries/subscription.queries";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userTypes } from "util/util.constant";
import { SubscriptionPlansColumns } from "./constants";

export default function useSubscriptionPlansContainer() {
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [initialValues, setInitialValues] = useState({});
    const [loader, setLoader] = useState(false);
    const [search, setSearch] = useState(null);
    const [filterByStatus, setFilterByStatus] = useState(null);
    const selectedIDRef = useRef(null);

    let userQueryPayload = {
        page,
        isPagination: true,
        type: userTypes.user,
        take: PAGE_SIZE,
        search: search,
        status: filterByStatus
    };
    const {
        data: dataSubscriptionPlan,
        isLoading: isLoading_SubscriptionPlan,
        refetch: refetchSubscriptionPlan
    } = useQuerySubscriptionPlan(userQueryPayload);
    const { data: multiPkgsOptions, refetch: refetchMultiPkgsOptions } = useQueryMultiPkgsList();
    const { data: dataSubscriptionOptions, isLoading: isLoading_SubscriptionList } = useQuerySubscriptionData();
    const { mutateAsync: updateStatusMutateQuery, isLoading: isLoading_UpdateStatus } = useQueryUpdateStatus();
    const { mutateAsync: updateEditPlanMutateQuery, isLoading: isLoading_EditPlan } = useMutationEditPlan();
    const { mutateAsync: CreatePlanusMutateQuery, isLoading: isLoading_CreatePlan } = useMutationCreatePlan();
    const { mutateAsync: deleteSubscriptionMutation, isLoading: isLoading_DeleteSubscription } =
        useMutationDeleteSubscription();

    const handleView = (id, additionalData) =>
        navigate(`/subscription-plans/detail/${id}`, { state: { additionalData } });

    const handleEdit = (res) => {
        selectedIDRef.current = res.id;
        const payload = {
            name: res.name,
            price: res.price,
            credits: res.credits,
            ...(res.appleId ? { ios_purchase_id: res.appleId } : null),
            ...(res.googleId ? { google_purchase_id: res.googleId } : null),
            discount_price: res.discountPrice,
            ...(res?.discountPackage ? { isDiscountedPackage: true } : false),
            ...(res?.subscriptionBy ? { IsSubscription: true } : false),
            subscription_by: res.subscriptionBy,
            discount_package: res?.discountPackage?.id
        };

        showModal(true, payload);
    };

    const handleDelete = (id) => {
        selectedIDRef.current = id;
        setDeleteModalVisibility(true);
    };
    const handleStatus = (value, id) => {
        const payload = {
            status: parseInt(value) ? "0" : "1"
        };

        updateStatusMutateQuery(
            { payload, id },
            {
                onSuccess: (res) => {
                    message.success(res?.message);
                    refetchSubscriptionPlan(userQueryPayload);
                    refetchMultiPkgsOptions();
                },
                onError: (err) => {
                    message.error(err?.message);
                }
            }
        );
    };

    const handleDeleteModalConfirm = async () => {
        const idToDelete = selectedIDRef.current;
        try {
            const response = await deleteSubscriptionMutation({
                id: idToDelete
            });
            message.success(response?.message);
            refetchSubscriptionPlan();
            refetchMultiPkgsOptions();
            setDeleteModalVisibility(false);
        } catch (error) {
            console.log("error", error);
        }
    };

    const columns = SubscriptionPlansColumns(handleView, handleEdit, handleDelete, handleStatus);

    const handleCancel = () => {
        setIsModalVisible(false);
        setInitialValues({});
    };

    const handleFinish = async (values) => {
        const idToUpdatedItem = selectedIDRef.current;
        if (isEditing) {
            const EditPayload = {
                name: values.name,
                credits: values.IsSubscription ? null : parseInt(values.credits, 10),
                ...(values.ios_purchase_id ? { ios_purchase_id: values.ios_purchase_id } : null),
                ...(values.google_purchase_id ? { google_purchase_id: values.google_purchase_id } : null),
                price: values.price,
                discount_price: values.discount_price,
                ...(values.IsSubscription ? { subscription_by: values.subscription_by } : null),
                ...(values.isDiscountedPackage ? { discount_package: values.discount_package } : null)
            };
            updateEditPlanMutateQuery(
                { payload: EditPayload, id: idToUpdatedItem },
                {
                    onSuccess: (res) => {
                        refetchMultiPkgsOptions();
                        refetchSubscriptionPlan();
                        handleCancel();
                        notification.success({
                            message: res?.message
                        });
                    },
                    onError: (err) => {
                        notification.error({
                            message: err?.message
                        });
                    }
                }
            );
        } else {
            const payload = {
                name: values.name,
                credits: values.IsSubscription ? null : parseInt(values.credits, 10),
                google_purchase_id: values.google_purchase_id || null,
                ios_purchase_id: values.ios_purchase_id || null,
                price: values.price,
                discount_price: values.discount_price,
                subscription_by: values.IsSubscription ? values.subscription_by : null,
                discount_package: values.isDiscountedPackage ? values.discount_package : null
            };

            CreatePlanusMutateQuery(
                {
                    payload
                },
                {
                    onSuccess: (res) => {
                        refetchSubscriptionPlan();
                        refetchMultiPkgsOptions();
                        handleCancel();
                        notification.success({
                            message: res?.message
                        });
                    },
                    onError: (err) => {
                        notification.error({
                            message: err?.message
                        });
                    }
                }
            );
        }

        setLoader(false);
    };

    const showModal = (edit = false, values = {}) => {
        setIsEditing(edit);
        setInitialValues(values);
        setIsModalVisible(true);
    };

    const handleFilterChange = (value) => {
        setFilterByStatus(value);
    };

    const handleCreatePlan = () => {
        showModal(false);
    };
    const handleSearch = (value) => {
        setSearch(value);
    };

    const PackagesTransformList = multiPkgsOptions?.data?.map((item) => ({
        label: item.name,
        value: item.id,
        price: item.price,
        credits: item.credits
    }));
    const SubscriptiontransformList = dataSubscriptionOptions?.map((item) => ({
        label: item.key,
        value: item.value
    }));
    return {
        search,
        columns,
        setPage,
        page,
        setDeleteModalVisibility,
        deleteModalVisibility,
        handleDeleteModalConfirm,
        isModalVisible,
        handleCancel,
        handleFinish,
        initialValues,
        isEditing,
        handleFilterChange,
        handleCreatePlan,
        loader,
        SubscriptiontransformList,
        dataSubscriptionPlan,
        refetchSubscriptionPlan,
        isLoading_CreatePlan,
        handleSearch,
        filterByStatus,
        PackagesTransformList,
        isLoading:
            isLoading_UpdateStatus ||
            isLoading_SubscriptionList ||
            isLoading_SubscriptionPlan ||
            isLoading_EditPlan ||
            isLoading_DeleteSubscription
    };
}

